import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';

import { useAPI } from 'context/api-context';
import { useAuth } from 'context/auth-context';
import { useLocale } from 'context/locale-context';

import Auth, {
  AuthContainer,
  AuthLink,
  AuthButton,
  AuthMessage,
  FormContainer,
} from '../Auth';
import { Input, InputError, InputErrorMessage } from 'components/Inputs';
import TFAAuth from './TFAAuth';
import TFACheckbox from './TFACheckbox';

import { setAllTokens } from 'shared/helpers/localStorage';
import inputValidation from 'shared/helpers/inputsValidation';
import { hasLowerCase, hasUpperCase, hasNumber } from 'shared/helpers/RegExp';

import {
  JWTTokenResponse,
  ErrorResponse,
  ResponseWithCode,
  User,
  TFAResponse,
} from 'shared/interfaces';

type LoginResponse = JWTTokenResponse & ErrorResponse & ResponseWithCode & TFAResponse;
type UserResponse = User & ErrorResponse;
type RegisterResponse = User & ErrorResponse & ResponseWithCode;

enum LoginType {
  normal = 'normal',
  tfaEnabled = 'tfaEnabled',
  tfaVerify = 'tfaVerify',
}

const Login = () => {
  const { fetchAPI } = useAPI();
  const { setUser, setUserCompleted } = useAuth();
  const { getLocaleOption } = useLocale();

  const [fetching, setFetching] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [userName, setUserName] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [confirmPasswordError, setConfirmPasswordError] = useState(false);
  const [errors, setErrors] = useState<InputError>({
    userName: false,
    password: false,
    confirmPassword: false,
  });
  const [firstLogin, setFirstLogin] = useState(false);
  const [secret, setSecret] = useState<string | null>(null);
  const [tfaChecked, setTfaChecked] = useState(false);
  const [tfaEnabled, setTfaEnabled] = useState(false);
  const [tfaCheckboxDisabled, setTfaCheckboxDisabled] = useState(false);
  const [tfaError, setTfaError] = useState<string | null>(null);

  const { search } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const searchParams = new URLSearchParams(search);
    const secret = searchParams.get('secret');
    const userName = searchParams.get('userName');

    if (secret && userName) {
      // this is invite link - first time user got to login page from email

      const validate = async () => {
        const json = await fetchAPI('/auth/invite/_validate/', {
          method: 'POST',
          withToken: false,
          body: JSON.stringify({ email: userName, secret }),
        });

        if (json.valid) {
          setFirstLogin(true);
          setUserName(userName);
          setSecret(secret);
        } else {
          navigate('/login');
        }
      };

      validate();
    } else {
      setFirstLogin(false);
    }
  }, [search, fetchAPI, navigate]);


  useEffect(() => {
    const searchParams = new URLSearchParams(search);
    const id = searchParams.get('id');

    if (id) {

      const checkMFAEnabled = async () => {
        const json = await fetchAPI(`/clinics/${id}/_attributes/`, {
          method: 'GET',
          withToken: false,
        });

        if (json.enforceMFAEnabled) {
          setTfaChecked(true);
          setTfaCheckboxDisabled(true);
        }
      };

      checkMFAEnabled();

    } else {
      setFirstLogin(false);
    }
  }, [search, fetchAPI, navigate]);

  const login = async (loginType: LoginType = LoginType.normal, tfaCode?: string) => {
    const verify = loginType === LoginType.tfaVerify;

    const loginBody =
      verify && tfaCode
        ? {
            mfaSecret: tfaCode,
          }
        : {
            password: btoa(password.trim()),
          };

    const loginResponse: LoginResponse = await fetchAPI(
      `/auth/${verify ? '_verify_mfa' : '_signin'}/`,
      {
        method: 'POST',
        withToken: false,
        body: JSON.stringify({ userName: userName.trim(), ...loginBody }),
      }
    );

    if (!loginResponse || loginResponse.code === 401) {
      setFetching(false);
      const errMessage = loginResponse.message;
      verify ? setTfaError(errMessage) : setErrorMessage(errMessage);
      return;
    }

    if (loginResponse.error) {
      setFetching(false);
      console.error(loginResponse);
      setErrorMessage(loginResponse.error);
      return;
    }

    if (loginResponse.mfaEnabled) {
      setFetching(false);
      setTfaEnabled(true);
      return;
    }

    const { access_token, refresh_token, accessTokenValidUntil, refreshTokenValidUntil } =
      loginResponse;

    setAllTokens(
      access_token,
      refresh_token,
      accessTokenValidUntil,
      refreshTokenValidUntil
    );

    const userResponse: UserResponse = await fetchAPI('/auth/_me/', {
      method: 'GET',
      withToken: false,
      customHeaders: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${access_token}`,
      },
    });

    if (!userResponse || userResponse.error) {
      setFetching(false);
      console.error(userResponse);
      setErrorMessage(userResponse.message);
      return;
    }

    if (userResponse.allowedToLogin) {
      setFetching(false);
      setUserCompleted(!!userResponse.person?.firstName);
      setUser(userResponse);
    }
  };

  const tfaLogin = (code: string) => login(LoginType.tfaVerify, code);

  const submitHandler: React.FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();

    const { hasError, errors } = inputValidation({ userName, password });
    setErrors(errors);
    if (hasError) return;

    setFetching(true);
    setErrorMessage('');

    if (firstLogin) {
      // this is kind of registration

      if (!hasLowerCase(password) || !hasUpperCase(password) || !hasNumber(password)) {
        setErrors((prevErrors) => {
          return {
            ...prevErrors,
            password: true,
            confirmPassword: true,
          };
        });
        setFetching(false);
        return setErrorMessage(getLocaleOption('checkPasswordSuggestions'));
      }

      setConfirmPasswordError(false);

      const { hasError, errors } = inputValidation({
        userName,
        password,
        confirmPassword,
      });

      setErrors(errors);

      if (hasError) return;

      if (password !== confirmPassword) {
        setErrors((prevErrors) => {
          return {
            ...prevErrors,
            password: true,
            confirmPassword: true,
          };
        });
        setFetching(false);
        return setConfirmPasswordError(true);
      }

      setFetching(true);
      setErrorMessage('');

      const registerResponse: RegisterResponse = await fetchAPI('/users/_register/', {
        method: 'POST',
        body: JSON.stringify({
          userName: userName.trim(),
          password: btoa(password.trim()),
          secret,
          mfaEnabled: tfaChecked,
        }),
      });

      if (registerResponse.code === 400) {
        setFetching(false);
        return setErrorMessage(registerResponse.message);
      }

      if (registerResponse.error) {
        setFetching(false);
        console.error(registerResponse);
        return setErrorMessage(registerResponse.error);
      }

      login(tfaChecked ? LoginType.tfaEnabled : LoginType.normal);
      setTfaEnabled(tfaChecked);
    } else {
      // this is "normal" login
      login();
    }
  };

  return (
    <Auth title={tfaEnabled ? '' : getLocaleOption('login')}>
      <AuthContainer>
        {tfaEnabled ? (
          <TFAAuth
            email={userName}
            tfaLogin={tfaLogin}
            tfaError={tfaError}
            setTfaError={setTfaError}
          />
        ) : (
          <>
            <form onSubmit={submitHandler}>
              <FormContainer>
                <Input
                  type='text'
                  value={userName}
                  setValue={setUserName}
                  id='username'
                  label={getLocaleOption('username')}
                  error={errors.userName}
                />
                <Input
                  type='password'
                  value={password}
                  setValue={setPassword}
                  passwordFeedback={firstLogin}
                  id='password'
                  label={`${firstLogin ? '* ' : ''} ${getLocaleOption('password')}`}
                  showErrorMessage={!firstLogin}
                  error={errors.password}
                />
                {firstLogin && (
                  <>
                    <Input
                      type='password'
                      value={confirmPassword}
                      setValue={setConfirmPassword}
                      id='password-confirm'
                      label={`* ${getLocaleOption('confirmPassword')}`}
                      showErrorMessage={false}
                      error={errors.confirmPassword}
                    />
                    <TFACheckbox checked={tfaChecked} setChecked={setTfaChecked} disabled={tfaCheckboxDisabled} />
                  </>
                )}
                <AuthButton
                  label={getLocaleOption(firstLogin ? 'register' : 'login')}
                  loading={fetching}
                />
                {firstLogin && (errors.password || errors.confirmPassword) && (
                  <div className='px-3'>
                    <InputErrorMessage
                      message={getLocaleOption('passwordConfirmPassword')}
                    />
                  </div>
                )}
                <AuthMessage>
                  {confirmPasswordError
                    ? getLocaleOption('passwordNotSameError')
                    : errorMessage}
                </AuthMessage>
              </FormContainer>
            </form>
            <AuthLink to='/forgot-password'>{getLocaleOption('forgotPassword')}</AuthLink>
          </>
        )}
      </AuthContainer>
    </Auth>
  );
};

export default Login;
