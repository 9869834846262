import React from 'react';

import { useLocale } from 'context/locale-context';

import { Checkbox } from 'primereact/checkbox';
import { Tooltip } from 'primereact/tooltip';

interface TFACheckboxProps {
  checked: boolean;
  setChecked: (value: React.SetStateAction<boolean>) => void;
  disabled?: boolean;
}

const TFACheckbox = ({ checked, setChecked, disabled = false }: TFACheckboxProps) => {
  const { getLocaleOption } = useLocale();

  return (
    <div className='field-radiobutton px-3'>
      <Checkbox
        inputId='2fa-checkbox'
        checked={checked}
        onChange={(e) => setChecked(e.checked)}
        disabled={disabled}
      />
      <label htmlFor='2fa-checkbox' className='text-xs'>
        {getLocaleOption('2faWantToSecure')}
        <span
          data-pr-tooltip={getLocaleOption('2faTooltip')}
          data-pr-position='top'
          data-pr-at='right-4 top'
          className='button-link'
        >
          {' '}
          (?)
        </span>
      </label>
      <Tooltip target='.button-link' className='w-7' />
    </div>
  );
};

export default TFACheckbox;
